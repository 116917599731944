/* Sadece özel ve override edilmesi gereken stiller */

.loading-spinner {
  @apply flex items-center justify-center min-h-screen text-xl text-gray-600;
}

.error-container {
  @apply flex items-center justify-center min-h-screen text-red-600;
}

.delete-button {
  @apply bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700 transition-colors;
}

.delete-button-small {
  @apply bg-red-500 text-white text-sm px-2 py-1 rounded hover:bg-red-600 transition-colors;
}

/* Chart container için özel stil */
.chart-container {
  @apply w-full h-48 relative;
}