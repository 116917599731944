/* frontend/src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .menu-button {
    @apply flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors;
  }

  .report-menu {
    @apply mt-4 p-4 bg-white rounded-lg shadow-md;
  }
  
  .receiver-domain-selection h3,
  .organization-selection h3,
  .report-list h3 {
    @apply text-lg font-medium mb-2;
  }
  
  .receiver-domain-selection ul,
  .organization-selection ul,
  .report-list ul {
    @apply list-none p-0 m-0 max-h-48 overflow-y-auto;
  }
  
  .receiver-domain-selection li,
  .organization-selection li,
  .report-list li {
    @apply p-2 cursor-pointer hover:bg-gray-100 rounded flex items-center;
  }
  
  .receiver-domain-selection li span,
  .organization-selection li span {
    @apply truncate;
  }
  
  .report-list li span {
    @apply truncate mr-2;
  }

  .dmarc-report {
    @apply bg-white p-4 rounded-lg shadow-md mt-4;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
}

@keyframes scale {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.animate-fade-out {
  animation: fadeOut 2s ease-out forwards;
}

.animate-scale {
  animation: scale 0.3s ease-out forwards;
}